
export const lists = [];

for (let i = 0; i < 100; i++) {
    lists.push({
        id: i,
        testId: 'TEST12' + i,
        headerName: 'Population of canada',
        date: '25 Aug, 2019',
        assignTo: 'Kazi Shahin',
        testName: 'Mini Mock Test',
        createdAt: '25 May, 2019, 9:15pm',
        status: 'Active'
    });

}

export const assignToTrainerOrAdmin = [];

for (let i = 0; i < 100; i++) {
    assignToTrainerOrAdmin.push({
        id: i,
        testId: 'TEST12' + i,
        testTitle: 'Population of canada',
        date: '25 Aug, 2019',
        testName: 'Mini Mock Test',
        assignTo: 'Kazi Shahin',
        createdAt: '25 May, 2019, 9:15pm',
        status: 'Active'
    });
}

export const assignToBranch = [];

for (let i = 0; i < 100; i++) {
    assignToBranch.push({
        id: i,
        testId: 'TEST12' + i,
        headerName: 'Population of canada',
        numberOfQuestion: 'RA 6 | RS 10 | DI 10',
        testType: 'Mini Mock Test',
        branch: 'Dhanmondi',
        createdAt: '25 May, 2019, 9:15pm',
        status: 'Active'
    });
}

export const drafts = [];

for (let i = 0; i < 100; i++) {
    drafts.push({
        id: i,
        testId: 'TEST12' + i,
        headerName: 'Population of canada',
        date: '25 Aug, 2019',
        testType: 'Mini Mock Test',
        assignTo: 'Kazi Shahin',
        lastModified: '25 May, 2019, 9:15pm',
    });
}


export const communications = [];

for (let i = 0; i < 100; i++) {
    communications.push({
        id: i,
        testId: 'TEST12' + i,
        headerName: 'Population of canada',
        date: '25 Aug, 2019',
        testType: 'Mini Mock Test',
        assignTo: 'Kazi Shahin',
        status: 'Active'
    });
}
